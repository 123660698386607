// const sum = require("./module/sum.js");
// console.log(sum(3, 2));


const modalForm = document.getElementById('modal_form')
if (modalForm) {
  modalForm.addEventListener('show.bs.modal', event => {
    // Button that triggered the modal
    const button = event.relatedTarget
    // Extract info from data-bs-* attributes
    const recipient = button.getAttribute('data-bs-title')
    // If necessary, you could initiate an Ajax request here
    // and then do the updating in a callback.

    // Update the modal's content.
    const modalTitle = modalForm.querySelector('.modal-title')
    const modalBodyInput = modalForm.querySelector('#form_type')

    modalTitle.textContent = `${recipient}`
    modalBodyInput.value = recipient
  })
}


var body = document.body;
  var burgerMenu = document.getElementsByClassName("b-menu")[0];
  var burgerMenuOver = document.getElementsByClassName("header_burgermenu_over")[0];
  var burgerContain = document.getElementsByClassName("b-container")[0];
  var burgerNav = document.getElementsByClassName("b-nav")[0];

burgerMenu.addEventListener(
    "click",
    function toggleClasses() {
      [body, burgerContain, burgerNav, burgerMenuOver].forEach(function (el) {
        el.classList.toggle("open");
      });
    },
    false
  );
burgerMenuOver.addEventListener(
    "click",
    function toggleClasses() {
      [body, burgerContain, burgerNav, burgerMenuOver].forEach(function (el) {
        el.classList.toggle("open");
      });
    },
    false
  );


// active class of menu onscroll
let headerMenu = document.querySelector('.header');
window.addEventListener('scroll', () => {
	let scrollDistance = window.scrollY;
  // console.log(scrollDistance);

  if (scrollDistance > 50){
    headerMenu.classList.add('active');
  } else {
    headerMenu.classList.remove('active');
  }

  /*-*/
  document.querySelectorAll('.infrastructure_content_item').forEach((el, i) => {
    if (el.offsetTop - document.querySelector('.infrastructure_sidebar_nav').clientHeight <= scrollDistance) {
      document.querySelectorAll('.infrastructure_sidebar_nav a').forEach((el) => {
        if (el.classList.contains('active')) {
          el.classList.remove('active');
        }
      });

      document.querySelectorAll('.infrastructure_sidebar_nav li')[i].querySelector('a').classList.add('active');
    }
  });
  /*-*/

});
let scrollDistanceDef = window.scrollY;
console.log(scrollDistanceDef);
if (scrollDistanceDef > 50){
  headerMenu.classList.add('active');
} else {
  headerMenu.classList.remove('active');
}



// active class of menu items onscroll
// window.addEventListener('scroll', () => {
// 	let scrollDistance = window.scrollY;

// 	if (window.innerWidth > 768) {
// 	}

// });



const mapya = document.getElementById('map')
if (mapya != null) {
  ymaps.ready(init);
  
  function init () {
      var myMap = new ymaps.Map("map", {
              center: [56.846437, 60.589300],
              zoom: 14
          }),
          myPlacemark = new ymaps.Placemark([56.846437, 60.589300], {
              // Чтобы балун и хинт открывались на метке, необходимо задать ей определенные свойства.
              // balloonContentHeader: "Септики",
              // balloonContentBody: "Москва, Дмитровское ш., 100, стр. 2",
              // balloonContentFooter: "Подвал",
              hintContent: "Екад Южный"
          });
  
      myMap.geoObjects.add(myPlacemark);
  
  }
}

const locmap = document.getElementById('locmap')
if (locmap != null) {
  ymaps.ready(init);

  function init() {
      var myMap = new ymaps.Map("locmap", {
          center: [56.689650, 60.599639],
          zoom: 15
      });
  
      // Создаем многоугольник, используя класс GeoObject.
      var myGeoObject = new ymaps.GeoObject({
          // Описываем геометрию геообъекта.
          geometry: {
              // Тип геометрии - "Многоугольник".
              type: "Polygon",
              // Указываем координаты вершин многоугольника.
              coordinates: [
                  // Координаты вершин внешнего контура.
                  [
                      [56.694145, 60.602258],
                      [56.692904, 60.605641],
                      [56.691785, 60.606165],
                      [56.690566, 60.604433],
                  ],
                  // // Координаты вершин внутреннего контура.
                  // [
                  //     [55.75, 37.82],
                  //     [55.75, 37.98],
                  //     [55.65, 37.90]
                  // ]
              ],
              // Задаем правило заливки внутренних контуров по алгоритму "nonZero".
              fillRule: "nonZero"
          },
          // Описываем свойства геообъекта.
          properties:{
              // Содержимое балуна.
              balloonContent: "Многоугольник"
          }
      }, {
          // Описываем опции геообъекта.
          // Цвет заливки.
          fillColor: '#00984638',
          // Цвет обводки.
          strokeColor: '#009846',
          // Общая прозрачность (как для заливки, так и для обводки).
          opacity: 0.9,
          // Ширина обводки.
          strokeWidth: 3,
          // Стиль обводки.
          strokeStyle: 'solid'
      });
  
      // Добавляем многоугольник на карту.
      myMap.geoObjects.add(myGeoObject);
  
      // // Создаем многоугольник, используя вспомогательный класс Polygon.
      // var myPolygon = new ymaps.Polygon([
      //     // Указываем координаты вершин многоугольника.
      //     // Координаты вершин внешнего контура.
      //     [
      //         [55.75, 37.50],
      //         [55.80, 37.60],
      //         [55.75, 37.70],
      //         [55.70, 37.70],
      //         [55.70, 37.50]
      //     ],
      //     // Координаты вершин внутреннего контура.
      //     [
      //         [55.75, 37.52],
      //         [55.75, 37.68],
      //         [55.65, 37.60]
      //     ]
      // ], {
      //     // Описываем свойства геообъекта.
      //     // Содержимое балуна.
      //     hintContent: "Многоугольник"
      // }, {
      //     // Задаем опции геообъекта.
      //     // Цвет заливки.
      //     fillColor: '#00FF0088',
      //     // Ширина обводки.
      //     strokeWidth: 5
      // });
  
      // // Добавляем многоугольник на карту.
      // myMap.geoObjects.add(myPolygon);
  }
  
}



/**
 * Element.requestFullScreen() polyfill
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!Element.prototype.requestFullscreen) {
	Element.prototype.requestFullscreen = Element.prototype.mozRequestFullscreen || Element.prototype.webkitRequestFullscreen || Element.prototype.msRequestFullscreen;
}

// Listen for clicks
document.addEventListener('click', function (event) {

	// Check if clicked element is a video thumbnail
	var videoId = event.target.getAttribute('data-video');
	if (!videoId) return;

	// Create iframe
	var iframe = document.createElement('div');
	iframe.innerHTML = '<p>x</p><iframe class="video_item_frame posa" src="https://www.youtube.com/embed/' + videoId + '?rel=0&autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>';
	var video = iframe.childNodes[1];

	// Replace the image with the video
	event.target.parentNode.replaceChild(video, event.target);

	// Enter fullscreen mode
	// video.requestFullscreen();
	
}, false);